import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const CMCGoldProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              CMC Gold.<br />
              Inspired locally.<br />
              Manufactured in China.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              In 2017, Chloe and Maurice started their business CMC Gold, with the idea of selling party supplies. The original concept grew into other product categories in 2018 with the founders making the decision to branch out into personalised baby products.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Like many businesses, CMC Gold had issues with their existing manufacturing suppliers around scaling the product orders to a higher volume.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Enter Sourci - Here's how we made amazing happen for CMC Gold.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/cmc-gold-1.webp"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-3 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$50K</span>
            </p>
            <p className="font-black text-xl">ORDER VALUE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>2 YEARS</span>
            </p>
            <p className="font-black text-xl">SOURCI CUSTOMER</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>SCALED</span>
            </p>
            <p className="font-black text-xl">INTO WHOLESALE</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/cmc-gold-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Wholesale or no sale
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              If you have ever experienced rapid growth, it’s one of the most unique business experiences you can have. Inevitably, you’re faced with needing to expand or risk falling behind your competition.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With CMC Gold, they spoke to their existing manufacturing supplier in Bali to see if they can expand their order quantities. Unfortunately, the manufacturer was too small to do the volume orders they required. Chloe and Maurice then reached out to Sourci, and the rest is history!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Since 2019, Sourci has been helping the business to expand their product range into baby swaddles sets and name plaques at an order value of AUD$50,000. With reliable suppliers, CMC Gold have maintained their low defect rate and quality standards. With Sourci's help, Chloe and Maurice were able to scale up in 2020 and achieved a new revenue stream into wholesale.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team.
            <span className="block text-sourci-pink">Push the button. Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              How Sourci’s Expertise & Persistence Defied The Odds
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              After one of the most extensive research & product development undertakings ever encountered, Noonie Australia now have a rock-solid supply chain that will see them grow their impact to newfound mothers far and wide throughout the foreseeable future.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With the first production run of 50,000 organic, world first Noonie Padsicles successfully delivered in mid-2021, their brand is set to explode thanks to heavy interest from hospitals, pharmacies, government and mums-to-be all over the country.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Their goal is to have a sustainable and environmentally conscious business that helps as many mums as they can. Look out for further eco and functional iterations as we scale their product offering with them.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Changing the world is well worth the wait - so watch this space!
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/noonie-3-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - CMC Gold" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="cmc-gold"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default CMCGoldProject;

export function Head() {
  return (
    <Seo title="CMC Gold | Our Projects | Sourci"
      description="Discover how Sourci manufactured and imported CMC Gold's diverse range of products."
      canonical="/projects/cmc-gold" />
  )
}